.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
  font-family: 'Raleway', sans-serif;
}

.logo {
  width: 200px;
  padding: 20px;
  color: white;
  font-weight: bold;
}

.link {
  text-decoration: none;
  width: 100%;
}

.logout {
  text-decoration: none;
  bottom: 0;
  left: 0;
  position: absolute;
  margin-bottom: 20px;
  width: 100%;
}

.sidebarItem {
  padding: 8px 16px;
  margin: 4px 12px;
  border-radius: 4px;
  color: white;
}

.itemGrid {
  display: inline-grid;
  grid-template: auto / auto auto;
  grid-gap: 10px;
  align-items: center;
}

.active > div {
  background: #1f285a;
}

.sidebarItem > p {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.sidebarItem:hover {
  cursor: pointer;
  background: #25306c;
}
