.outerBox {
  background-color: white;
}

.form {
  padding-top: 40px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.form div {
  margin-bottom: 10px;
}

.form div label {
  margin-bottom: 5px;
}

.submit {
  margin: 0 auto;
  padding-bottom: 30px;
  width: 80%;
}

.submit button {
  width: 100%;
}

.successText {
  text-align: center;
  padding-bottom: 30px;
}
