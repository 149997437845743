html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Arial';
  background: #3f51b5;
  height: 100%;
  width: 100%;
}

div[id='main'] {
  positive: relative;
  margin-left: 250px;
  padding: 20px;
  min-height: calc(100vh - 40px);
  width: calc(100% - 290px);
  background: white;
  z-index: 2;
  border-radius: 20px 0 0 20px;
}
