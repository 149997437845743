.dataTable {
  width: 100%;
  height: 500px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.centered {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.modal {
  min-width: 500px;
}

.modalTitle {
  text-align: center;
  font-size: 40px;
  font-weight: 200;
  color: var(--color-text-green);
  padding-top: 40px;
  background-color: white;
}
