.centered {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.modal {
  min-width: 500px;
  background-color: white;
}

.modalTitle {
  text-align: center;
  font-size: 40px;
  font-weight: 200;
  padding-top: 40px;
  background-color: white;
}

.buttons {
  text-align: center;
  margin: 30px 0px;
}

.buttons > button {
  margin: 0px 5px;
}
